import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import { Link } from "react-router-dom";
import { CardBody,Progress} from "reactstrap";
import SolidButton from "../componentes/buttons/SolidButton";
import server from "../config/server";
import {Animated} from "react-animated-css";
import { useDispatch } from "react-redux";
import General from "../componentes/modals/General";
import TodosEncuentros from "../componentes/modals/TodosEncuentros";
import imgvacia from '../assets/imagenes/fans-septiembre/generales/boy1-sm.png'


function GraficosVotaciones(props) {
  console.log("locals",localStorage.getItem("idEncuentro"))
  const [idEncuentro,setIdEncuentro]= useState(localStorage.getItem("idEncuentro") ? (localStorage.getItem("idEncuentro")).trim() :0 );
  const API = server.baseURL + `fanatico/votaciones/list/${idEncuentro}`;
  const [calificaciones, setCalificaciones] = useState([]);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const dispatch = useDispatch();

  useEffect(() => {
    fetch(API, {
      method: "GET",
      params: {
        t: new Date().getTime()
      }

    })
      .then((response) => response.json())
      .then((data) => {
        if(data.data){
           setCalificaciones(data.data);
        }
        console.log('dataCal', data)  
      })

      .catch((error) => {
        console.log(error);
      });
  }, [idEncuentro]);
 setTimeout(() => {
   setIdEncuentro(localStorage.getItem("idEncuentro") ? localStorage.getItem("idEncuentro").trim() : 0 )
 }, 1000);
 
 

  const renderCalificacion = (obj) => {
    return obj.map((cal) => {
      const calificacion = parseInt(cal.promedio);
      const porcentaje = parseInt(cal.porcentaje);
      const futbolista = cal.alias_futbolista ;
      const puntos = cal.votos;
      const participantes = cal.cantidad;
      const imagen = cal.imagen_futbolista !== "" ? cal.imagen_futbolista : imgvacia ;
      const id = cal.id_futbolista
      let color = "";
      console.log(porcentaje)
      switch (true) {
        case porcentaje >= 0 && porcentaje <= 49:
          color = 'warning';
          break;
        case porcentaje >= 50 && porcentaje <= 79:
          color = 'primary';
          break;
        case porcentaje >= 80 && porcentaje <= 100:
          color = 'success';
          break;
      }
      //console.log(color)
      return (
        <li className="mb-4 glass" key= {id}>
          <Animated animationIn="flipInY" animationOut="flipOutY" animationInDuration={3000} animationOutDuration={3000} isVisible={true}>
          <div className="d-flex align-items-center" >
            <small style={{display:"flex",flexDirection:"row"}}>
              <div style={{display:"flex",alignItems:"center"}}>
              <img className="camiseta" src={`${config_server.linkImageFutbolista}${imagen}`} alt={futbolista}/>
              </div>
              <div>
              <h2 className="color-white">{futbolista}</h2>
              <h5 style={{color:"white", marginLeft:"1rem"}}>Puntos: {puntos} - Participantes: {participantes}</h5>
              </div>
            </small>
            <div className="ml-auto">
            
              <h2 className="h1-1">{calificacion}</h2>
              
            </div>
          </div>
           
          <Progress width="500px"
          height="10px"value={porcentaje} animated color={color}></Progress>
          
          </Animated>
        </li>
      );
    });
  };

  // danger 0 al 40
  // warning 41 al 80
  // success 81 al 100

  return (
    <React.Fragment>
      <section className="container-gr">
        <div className="container-azul2">
          <div className="cab-verde" >
            <h1 className="h1-1 centrar">Calificaciones <General/></h1>
            

          </div>
          <div className="container-azul">
            <CardBody>
              <div className="">
                <div>
                  
                  {calificaciones.length === 0 ? (
                    <div/>
                  ) : (
                    <ul className="list-style-none country-state mt-4" style={{marginLeft:"-4rem"}}>
                      {renderCalificacion(calificaciones)}
                      </ul>
                  )} 
                 
                </div>
              </div>
              
            </CardBody>
            <div className="centrar">
            
            <Link to="/calificaciones-encuentros" className="centrar">
          <SolidButton
            type="red-agregar-c"
            txt="Ver todos"
          ></SolidButton>
          
        </Link>
        <TodosEncuentros/> 
        </div>
          </div>
          
        </div>
      </section>
    </React.Fragment>
  );
}
export default GraficosVotaciones;

