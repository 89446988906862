import React from "react";
import imgig from "../assets/imagenes/fans-septiembre/generales/ig.png";
import imgtw from "../assets/imagenes/fans-septiembre/generales/tw.png";
import imgfb from "../assets/imagenes/fans-septiembre/generales/fb.png"



function ContactoHome2() {


 
  // const dispatch = useDispatch();


  
  return (
    <React.Fragment>

      <section>

     
        <div className="container-azul223">
        <div className="cab-verde3" >
          <h1 className="h1-1 centrar">Contactanos</h1>
          </div>
          <div className="container-azul3">

              <div className="redes-cont3">
          <img className="redes-contacto3" src={imgig} alt="instagram"/>
          <img className="redes-contacto3" src={imgtw} alt="twitter"/>
          <img className="redes-contacto3" src={imgfb} alt="facebook" />
          </div>
          </div>
          </div>

       
      </section>
    </React.Fragment>
  );
}
export default ContactoHome2;
